<template>
  <div style="margin:5px;" class="rowTitle">

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1 backBlack text-center" role="tab" @click="invsummary = !!invsummary">
        {{$parent.$parent.lang.payments}} </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <v-simple-table striped hover style="width:100%;">
              <template v-slot:default>
                <thead>
                    <tr>
                        <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;">{{$parent.$parent.lang.payment_amount}}</th>
                        <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;width:100px;display:none;">{{$parent.$parent.lang.pay_by}}</th>
                        <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;width:100px;display:none;">{{$parent.$parent.lang.mobile}}</th>
                        <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;width:100px;">{{$parent.$parent.lang.cash}}</th>
                        <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;width:100px;">{{$parent.$parent.lang.span}}</th>
                        <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;width:50px;">{{$parent.$parent.lang.date}}</th>
                        <th class="backBlack" style="direction:rtl;padding:1px;text-align:center;width:50px;">{{$parent.$parent.lang.print}}</th>
                    </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in items"
                    :key="item.id"
                >
                  <td>{{ item.ftotal }}</td>
                  <td style="display:none;">{{ item.received_from }}</td>
                  <td style="display:none;">{{ item.mobile }}</td>
                  <td>{{ item.paycash }}</td>
                  <td>{{ item.payspan }}</td>
                  <td>{{ item.receipt_date }}</td>
                  <td>
                    <v-btn class="ma-1" 
                      style="height:30px;background:red;color:#FFF;" 
                      @click="doit(item.id,2)">
                      {{$parent.$parent.lang.print}}</v-btn></td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'CVLaborTable',
  components: {},
  data: () => ({
    invsummary:true,
    items:[
    ],
    description: '',
    qty: 1,
    price: 0,
    total: 0,
    cardid: 0
  }),
  created(){
    // this.getPayments();
  },
  methods: {
    printPDFx (id,t) 
    {
        let pdfFrame = document.body.appendChild(document.createElement('iframe'));
        pdfFrame.style.display = 'none';
        pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
        if(t == 1)
            pdfFrame.src = '../api/print.php?invid='+id;
        if(t == 2)
            pdfFrame.src = '../api/print.php?recpid='+id;
    },
    doit(id,t){
      this.printPDFx(id,t)
    },
    getPayments(id){
      const formdata = new FormData();
      formdata.append('defBranch',localStorage.getItem('defBranch'));
      formdata.append('financeYear',localStorage.getItem('financeYear'));
      formdata.append('type','getPayment');
      formdata.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      formdata.append('data[cardid]',this.cardid);
        axios.post(
          this.$SAMCOTEC.r_path, formdata
        ).then((response) => {
          const res = response.data; 
          this.items = res.results.data;
        });
      }
      
    },
    
  }
</script>
<style>
.rowTitle{
  background:#FFF;
  color:#000;
  font-size:14px;
  text-align: right;
  padding:3px 3px 5px 3px;
}
</style>